import { PanelPlugin } from '@grafana/data';
import { FlowOptions } from './types';
import { FlowPanel } from './FlowPanel';

export const plugin = new PanelPlugin<FlowOptions>(FlowPanel).setPanelOptions(builder => {
    return builder
        .addTextInput({ 
            name: 'Control step name',
            path: 'controlName'
        })
})
