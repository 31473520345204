import React, { Component } from 'react';
import { FlowChartProps, Machine, Operation } from 'types';
import Moment from 'react-moment';

class FlowChart extends Component<FlowChartProps> {
  constructor(props: Readonly<FlowChartProps>) {
    super(props);
    this.state = {
      operations: props.operations,
      options: props.options,
      part: props.part,
    };
  }

  state: FlowChartProps = {
    operations: [],
    options: { controlName: '' },
    part: { duration: 0, number: 0, operations: [] },
  };

  componentWillReceiveProps(props: Readonly<FlowChartProps>) {
    this.setState({ operations: [], part: 0 });

    setTimeout(() => {
      this.setState({ operations: props.operations, options: props.options, part: props.part });
    });
  }

  render() {
    const hours: number = Math.floor(this.state.part.duration / 60);
    const minutes: number = this.state.part.duration % 60;

    return (
      <div className="flow-chart">
        <div className="fc-part-number">
          <div className="fc-tooltip-common fc-parttooltip">
            <div className="fc-operation-name">Position {this.state.part.number}</div>
            <div>
              <i className="fa fa-clock-o fc-time" aria-hidden="true"></i>
              {hours}h {minutes}min
            </div>
          </div>
          {this.state.part.number}
        </div>
        {this.createMachines()}
      </div>
    );
  }

  createMachines(): React.ReactNode[] {
    const operaionAreas: React.ReactNode[] = [];
    if (!this.state.operations) {
      return [];
    }

    const operations = this.state.operations;
    operations.forEach((op, i) => {
      const machines = op.machines;
      const machineBoxes: React.ReactNode[] = [];
      machines.forEach((m, idx) => {
        let machineClass = 'fc-machine zoom-fade-in';
        if (m.OTD) {
          machineClass += ' bad';
        }

        const goodClass = m.good > 0 ? 'fc-good' : 'fc-greyed';
        const scrapClass = m.scrap > 0 ? 'fc-scrap' : 'fc-greyed-scrap';

        machineBoxes.push(
          <div className={machineClass}>
            <span className="fc-tooltip-common fc-machinetooltip">{this.getMachineTooltip(m)}</span>
            {m.name}
            <div>
              <span className={goodClass}>
                <i className="fa fa-cube" aria-hidden="true"></i>
                {m.good}
              </span>
              <span className={scrapClass}>
                <i className="fa fa-cube" aria-hidden="true"></i>
                {m.scrap}
              </span>
            </div>
          </div>
        );
        if (idx < op.machines.length - 1) {
          if (op.machines[idx + 1].name === this.props.options.controlName) {
            machineBoxes.push(this.machineArrow());
          } else {
            machineBoxes.push(this.noArrow());
            op.arrows = 'parallel';
          }
        }
      });
      let operationClass = 'fc-operation';
      if (op.OTD) {
        operationClass += ' bad';
      }
      if (op.number % 1 > 0) {
        operationClass += ' fc-hidden';
      }
      operaionAreas.push(
        <div className="fc-operation-area">
          <span className={operationClass}>
            <span className="fc-tooltip-common fc-operationtooltip">{this.getOperationTooltip(op)}</span>
            {op.number} ({this.percentage(op.duration)}%)
          </span>
          {machineBoxes}
        </div>
      );

      if (i < operations.length - 1) {
        const nextCount: number = operations[i + 1].machines.length;

        if (op.machines.length <= 1) {
          if (nextCount > 1 && operations[i + 1].arrows === 'parallel') {
            operaionAreas.push(this.oneToManyArrow(nextCount));
          } else {
            operaionAreas.push(this.oneToOneArrow());
          }
        } else {
          if (nextCount > 1 && operations[i + 1].arrows === 'parallel') {
            operaionAreas.push(this.lastToManyArrow(op.machines.length, nextCount));
          } else {
            operaionAreas.push(this.manyToOneArrow(op.machines.length, op.arrows === 'parallel'));
          }
        }
      }
    });

    return operaionAreas;
  }

  getOperationTooltip(op: Operation): React.ReactNode[] {
    const tooltip: React.ReactNode[] = [];
    tooltip.push(
      <div>
        <span className="fc-operation-name">
          [{op.number}] {op.name}
        </span>
      </div>
    );

    const hours: number = Math.floor(op.duration / 60);
    const minutes: number = op.duration % 60;

    tooltip.push(
      <div>
        <i className="fa fa-clock-o fc-time" aria-hidden="true"></i>
        {hours}h {minutes}min ({this.percentage(op.duration)}%)
      </div>
    );

    tooltip.push(
      <div>
        <span>Plan Stop: {this.parseDate(op.planStop)}</span>
      </div>
    );
    tooltip.push(
      <div>
        <span>Actual Stop: {this.parseDate(op.actualStop)}</span>
      </div>
    );

    if (op.OTD) {
      tooltip.push(
        <div>
          <i className="fa fa-times fc-otd-bad" aria-hidden="true"></i>OTD
        </div>
      );
    } else {
      tooltip.push(
        <div>
          <i className="fa fa-check fc-otd-good" aria-hidden="true"></i>OTD
        </div>
      );
    }
    return tooltip;
  }

  getMachineTooltip(m: Machine): React.ReactNode[] {
    const tooltip: React.ReactNode[] = [];
    tooltip.push(
      <div>
        <i className="fa fa-cog fc-machinename" aria-hidden="true"></i>
        <span className="fc-machinename">{m.name}</span>
      </div>
    );
    tooltip.push(
      <div>
        <span className="fc-good">
          <i className="fa fa-cube" aria-hidden="true"></i>
          {m.good}
        </span>
        <span className="fc-scrap">
          <i className="fa fa-cube " aria-hidden="true"></i>
          {m.scrap}
        </span>
      </div>
    );

    const hours: number = Math.floor(m.duration / 60);
    const minutes: number = m.duration % 60;

    tooltip.push(
      <div>
        <i className="fa fa-clock-o fc-time" aria-hidden="true"></i>
        {hours}h {minutes}min ({this.percentage(m.duration)}%)
      </div>
    );
    return tooltip;
  }

  noArrow(): React.ReactNode {
    return <div className="fc-machine-arrow"></div>;
  }

  machineArrow(): React.ReactNode {
    return (
      <div className="fc-machine-arrow fade-in">
        <svg width="10" height="30">
          <defs>
            <marker id="arrow" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto">
              <path d="M2,1 L2,10 L10,6 L2,2" />
            </marker>
          </defs>
          <path d="M5,0 L5,20" style={{ fill: 'none', strokeWidth: '1.25px', markerEnd: 'url(#arrow)' }} />
        </svg>
      </div>
    );
  }

  oneToOneArrow(): React.ReactNode {
    return (
      <div className="fc-area-arrow fade-in">
        <svg width="30" height="70">
          <defs>
            <marker id="arrow" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto">
              <path d="M2,1 L2,10 L10,6 L2,2" />
            </marker>
          </defs>
          <path d="M0,50 L20,50" style={{ fill: 'none', strokeWidth: '1.25px', markerEnd: 'url(#arrow)' }} />
        </svg>
      </div>
    );
  }

  //IN
  oneToManyArrow(itemsCount: number): React.ReactNode {
    const height: number = (itemsCount - 1) * 88 + 50;
    const d: string = 'M13,' + (height - 5) + ' L13,50 M0,50 L20,50';

    const arrows: React.ReactNode[] = [];

    for (let index = 1; index < itemsCount; index++) {
      const h: number = index * 88 + 45;
      const ad: string = 'M13,' + h + ' L20,' + h;
      arrows.push(<path d={ad} style={{ fill: 'none', strokeWidth: '1.25px', markerEnd: 'url(#arrow)' }} />);
    }

    return (
      <div className="fc-area-arrow fade-in">
        <svg width="30" height={height}>
          <defs>
            <marker id="arrow" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto">
              <path d="M2,1 L2,10 L10,6 L2,2" />
            </marker>
          </defs>
          <path d={d} style={{ fill: 'none', strokeWidth: '1.25px', markerEnd: 'url(#arrow)' }} />
          {arrows}
        </svg>
      </div>
    );
  }

  //OUT
  manyToOneArrow(itemsCount: number, parallel: boolean): React.ReactNode {
    const height: number = (itemsCount - 1) * 88 + 50;
    let d: string = 'M0,' + (height - 5) + ' L13,' + (height - 5) + ' L13,50 L20,50';

    if (parallel) {
      //make parallel arrow
      for (let index = 0; index < itemsCount - 1; index++) {
        const h: number = index * 88 + 50;
        d = 'M0,' + h + ' L13,' + h + ' ' + d;
      }
    }

    return (
      <div className="fc-area-arrow fade-in">
        <svg width="30" height={height}>
          <defs>
            <marker id="arrow" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto">
              <path d="M2,1 L2,10 L10,6 L2,2" />
            </marker>
          </defs>
          <path d={d} style={{ fill: 'none', strokeWidth: '1.25px', markerEnd: 'url(#arrow)' }} />
        </svg>
      </div>
    );
  }

  lastToManyArrow(itemsCount: number, nextItemsCount: number): React.ReactNode {
    const height: number = (itemsCount - 1) * 88 + 50;
    const d: string = 'M0,' + (height - 5) + ' L13,' + (height - 5) + ' L13,50 L20,50';

    const arrows: React.ReactNode[] = [];

    for (let index = 1; index < nextItemsCount; index++) {
      const h: number = index * 88 + 45;
      const ad: string = 'M13,' + h + ' L20,' + h;
      arrows.push(<path d={ad} style={{ fill: 'none', strokeWidth: '1.25px', markerEnd: 'url(#arrow)' }} />);
    }

    return (
      <div className="fc-area-arrow fade-in">
        <svg width="30" height={height}>
          <defs>
            <marker id="arrow" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto">
              <path d="M2,1 L2,10 L10,6 L2,2" />
            </marker>
          </defs>
          <path d={d} style={{ fill: 'none', strokeWidth: '1.25px', markerEnd: 'url(#arrow)' }} />
          {arrows}
        </svg>
      </div>
    );
  }

  parseDate(date: string): any {
    if (!date) {
      return <span className="fc-timestamp">IN PROGRESS</span>;
    }

    return <Moment className="fc-timestamp" date={date} format="DD/MM HH:mm" />;
  }

  percentage(duration: number): string {
    return ((duration / this.state.part.duration) * 100).toFixed(0);
  }
}
export default FlowChart;
