import React, { PureComponent } from 'react';
import { PanelProps } from '@grafana/data';
import { FlowOptions, Machine, Operation, OrderPart } from 'types';
import { loadPluginCss } from '@grafana/runtime';
import FlowChart from 'FlowChart';

interface Props extends PanelProps<FlowOptions> {}

export class FlowPanel extends PureComponent<Props> {
  cssLoaded = false;
  parts: OrderPart[] = [];

  render() {
    if (!this.cssLoaded) {
      this.cssLoaded = true;
      loadPluginCss({
        dark: 'plugins/order-machine-flow/css/base.css',
        light: 'plugins/order-machine-flow/css/base.css',
      }).then(() => this.setState({ cssLoaded: true }));
      return null;
    }

    if (this.props.data.state === 'Done') {
      this.getMachinesFromQuery();
      if (this.parts.length === 0) {
        return null;
      }
      this.separateControlOperations(this.props.options.controlName);
    }

    return <div className="flow-chart-container">{this.renderFlowCharts()}</div>;
  }

  separateControlOperations(control: string) {
    this.parts.forEach(p => {
      const operations: Operation[] = [];
      p.operations.forEach(op => {
        const machines: Machine[] = [];
        if (op.machines.length <= 2) {
          if (op.machines.length === 2 && op.machines[1].name === control) {
            op.arrows = 'flow';
          }
          operations.push(op);
          return;
        }
        op.machines.forEach(m => {
          if (m.name !== control) {
            machines.push(m);
          } else {
            const controlMachine: Machine = m;
            operations.push({
              number: +op.number + +0.5,
              machines: [controlMachine],
              OTD: op.OTD,
              actualStop: op.actualStop,
              name: op.name,
              planStop: op.planStop,
              arrows: 'na',
              duration: op.duration,
            });
          }
        });
        op.machines = machines;
        operations.push(op);
      });
      p.operations = operations.sort((a, b) => a.number - b.number);
    });
  }

  renderFlowCharts(): React.ReactNode[] {
    const { options } = this.props;
    const floawCharts: React.ReactNode[] = [];
    this.parts.forEach((p, i) => {
      floawCharts.push(<FlowChart part={p} operations={p.operations} options={options} />);

      if (i < this.parts.length - 1) {
        floawCharts.push(<div className="fc-divider"></div>);
      }
    });
    return floawCharts;
  }

  getMachinesFromQuery() {
    const partsDic: {
      [id: number]: {
        operations: {
          [id: string]: {
            operation: Operation;
            machines: {
              [id: string]: Machine;
            };
          };
        };
      };
    } = {};

    const table = this.props.data.series.find(s => s.refId === 'A');
    if (table !== undefined) {
      const colPart = table.fields[1].values.toArray();
      const colOperationNumber = table.fields[2].values.toArray();
      const colOperationName = table.fields[3].values.toArray();
      const colMachineName = table.fields[4].values.toArray();

      const colGood = table.fields[5].values.toArray();
      const colScrap = table.fields[6].values.toArray();
      const colDuration = table.fields[7].values.toArray();
      const colPlanStop = table.fields[8].values.toArray();
      const colActualStop = table.fields[9].values.toArray();
      const colOTD = table.fields[10].values.toArray();
      const colStart = table.fields[11].values.toArray();
      const colEnd = table.fields[12].values.toArray();

      table.fields[1].values.toArray().map((r: number, i) => {
        const m: Machine = {
          name: colMachineName[i],
          operations: [],
          OTD: colOTD[i],
          duration: colDuration[i],
          good: colGood[i],
          scrap: colScrap[i],
          start: colStart[i],
          end: colEnd[i],
        };

        if (!partsDic[colPart[i]]) {
          partsDic[colPart[i]] = { operations: {} };
        }

        if (!partsDic[colPart[i]].operations[colOperationNumber[i]]) {
          const op: Operation = {
            number: colOperationNumber[i],
            name: colOperationName[i],
            OTD: colOTD[i],
            planStop: colPlanStop[i],
            actualStop: colActualStop[i],
            machines: [],
            arrows: 'parallel',
            duration: colDuration[i],
          };
          partsDic[colPart[i]].operations[colOperationNumber[i]] = { operation: op, machines: {} };
        } else {
          //update to operation
          partsDic[colPart[i]].operations[colOperationNumber[i]].operation.duration += colDuration[i];
        }

        if (!partsDic[colPart[i]].operations[colOperationNumber[i]].machines[m.name]) {
          partsDic[colPart[i]].operations[colOperationNumber[i]].machines[m.name] = m;
        } else {
          //add to machine
          partsDic[colPart[i]].operations[colOperationNumber[i]].machines[m.name].duration += colDuration[i];
          partsDic[colPart[i]].operations[colOperationNumber[i]].machines[m.name].good += colGood[i];
          partsDic[colPart[i]].operations[colOperationNumber[i]].machines[m.name].scrap += colScrap[i];
        }
      });
    }
    const parts: OrderPart[] = [];
    for (const partKey in partsDic) {
      const operations: Operation[] = [];
      let partDuration = 0;
      for (const opKey in partsDic[partKey].operations) {
        const machines: Machine[] = [];
        for (const mKey in partsDic[partKey].operations[opKey].machines) {
          machines.push(partsDic[partKey].operations[opKey].machines[mKey]);
        }
        partsDic[partKey].operations[opKey].operation.machines = machines;
        operations.push(partsDic[partKey].operations[opKey].operation);
        partDuration += partsDic[partKey].operations[opKey].operation.duration;
      }
      const part: OrderPart = { number: +partKey, operations: operations, duration: partDuration };
      parts.push(part);
    }

    this.parts = parts;
  }
}
